import { GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const columns: GridColDef[] = [
  {
    flex: 2,
    headerAlign: 'center',
    align: 'left',
    field: 'kontoname',
    headerName: 'Kontoname',
    minWidth: 100,
    type: 'string',
    renderCell: (params) => (
      <Link to={`/konten/${params.row.kontoId}`}>
        {params.row.kontoname}
      </Link>
    ),
  },
  {
    flex: 1,
    field: 'saldoCcContainer',
    headerName: 'CC (TAG6)',
    headerAlign: 'center',
    align: 'center',
    minWidth: 120,
    type: 'string',
    valueGetter: (params) => {
      return params.row.saldo.ccContainer;
    },
  },
  {
    flex: 1,
    field: 'saldoCcContainerAlt',
    headerName: 'CC (TAG5)',
    headerAlign: 'center',
    align: 'center',
    minWidth: 120,
    type: 'string',
    valueGetter: (params) => {
      return params.row.saldo.ccContainerAlt;
    },
  },
  {
    flex: 1,
    headerAlign: 'center',
    field: 'ccBretter',
    headerName: 'Bretter',
    align: 'center',
    minWidth: 100,
    type: 'string',
    valueGetter: (params) => {
      return params.row.saldo.ccBretter;
    },
  },
  {
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    field: 'ccAufsetzer',
    headerName: 'Aufsetzer',
    minWidth: 100,
    valueGetter: (params) => {
      return params.row.saldo.ccAufsetzer;
    },
  },
  {
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    field: 'hollandContainer',
    headerName: 'Holland',
    minWidth: 100,
    valueGetter: (params) => {
      return params.row.saldo.hollandContainer;
    },
  },
  {
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    field: 'europaletten',
    headerName: 'Europaletten',
    minWidth: 100,
    valueGetter: (params) => {
      return params.row.saldo.europaletten;
    },
  },
];
