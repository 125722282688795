import {
  Configuration, KontoauszugDownloadTypDto,
  LadungstraegerBuchungDto,
  LadungstraegerControllerApiFactory,
  LadungstraegerKontoauszugDto,
  LadungstraegerKontoDto,
  LadungstraegerKontoSaldoDto,
} from '@nimeroo/kundenportal-backend-typescript-client';
import HttpService from '../utils/HttpService';
import { Konto } from '../dto/Konto';
import { Kontosaldo } from '../dto/Kontosaldo';
import { Kontoauszug } from '../dto/Kontoauszug';
import { Buchung } from '../dto/Buchung';

function toKontosaldo(dto: LadungstraegerKontoSaldoDto): Kontosaldo {
  return {
    datum: new Date(dto.datum),
    ccContainer: dto.ccContainer,
    ccContainerAlt: dto.ccContainerAlt,
    ccBretter: dto.ccBretter,
    ccAufsetzer: dto.ccAufsetzer,
    hollandContainer: dto.hollandContainer,
    europaletten: dto.europaletten,
  };
}

function toKonto(dto: LadungstraegerKontoDto): Konto {
  return {
    kontoId: dto.kontoId,
    kontoname: dto.kontoname,
    kontoart: dto.kontoart,
    saldo: toKontosaldo(dto.saldo),
  };
}

function toBuchung(dto: LadungstraegerBuchungDto): Buchung {
  return {
    id: dto.id,
    bewegungsdatum: new Date(dto.bewegungsdatum),
    rechnung: dto?.rechnung,
    rechnungsdatum: dto.rechnungsdatum ? new Date(dto.rechnungsdatum) : undefined,
    ccContainer: dto.bewegung.ccContainer,
    ccContainerAlt: dto.bewegung.ccContainerAlt,
    ccBretter: dto.bewegung.ccBretter,
    ccAufsetzer: dto.bewegung.ccAufsetzer,
    hollandContainer: dto.bewegung.hollandContainer,
    europaletten: dto.bewegung.europaletten,
    bewegungsArt: dto.bewegung.bewegungsart,
  };
}

function toKontoauszug(dto: LadungstraegerKontoauszugDto): Kontoauszug {
  return {
    kontoId: dto.kontoart,
    kontoname: dto.kontoname,
    kontoart: dto.kontoart,
    toSaldo: toKontosaldo(dto.toSaldo),
    fromSaldo: toKontosaldo(dto.fromSaldo),
    jahresbeginnSaldo: toKontosaldo(dto.jahresbeginnSaldo),
    buchungen: dto.buchungen.map((b) => toBuchung(b)),
    negativerHinweis: dto.negativerHinweis,
    positiverHinweis: dto.positiverHinweis,
  };
}

class KontenApi {
  kontenApi = LadungstraegerControllerApiFactory(new Configuration(),
    'https://api.kundenportal.db-blumen.de', HttpService.getAxiosClient());

  getKonten(): Promise<Konto[]> {
    return this.kontenApi.getLadungstraegerKonten()
      .then((value) => {
        return value.data.map((d) => toKonto(d));
      });
  }

  getKontoauszug(kontoId: string, from: string, to: string): Promise<Kontoauszug> {
    return this.kontenApi.getKontoauszug(kontoId, from, to)
      .then((value) => toKontoauszug(value.data));
  }

  getKontoauszugDownload(kontoId: string, from: string, to: string): Promise<any> {
    return this.kontenApi.getKontoauszugDownload(kontoId, from, to, KontoauszugDownloadTypDto.Csv, { responseType: 'blob' });
  }
}

export const kontenApi = new KontenApi();
